import React from 'react';

import clientesApi from '../../services/clientApi';

import ListPageLayout from '../../components/layout/ListPageLayout';

export default function ProductsBase() {
  return <ListPageLayout 
    menuTitle="Cadastro de Clientes"
    searchPlaceholder="Buscar cadastros"
    creationEndpoint="/cadastros/cadastro-clientes"
    creationLabel="Cadastrar Cliente"
    loader={clientesApi.all}
    columns={[
      { title: 'Razão Social', field: 'razao_social' },
      { title: 'CNPJ', field: 'cnpj', columnType: 'CNPJ' },
      { title: 'Nome Fantasia', field: 'nome_fantasia' },
      { title: 'Responsável', field: 'responsavel' },
      { title: 'Telefone', field: 'telefone_principal', columnType: 'phone' },
      { title: 'Latitude', field: 'latitude' },
      { title: 'Longitude', field: 'longitude' },
      { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
    ]}
  />;
}
