import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import LogoSrc from '../../../assets/images/logo.png';
import EstoqueSrc from '../../../assets/images/estoque.jpg';

import Title from '../../../components/text/Title';
import ColoredButton from '../../../components/button/ColoredButton';
import Input from '../../../components/input/Input';
import { LoginData } from '../../../types';
import Form from '../../../components/form/Form';
import authApi from '../../../services/authApi';
import useForm from '../../../hooks/useForm';
import Storage from '../../../helpers/Storage';

export default function Login() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { form, isFormInvalid, handleChange } = useForm<LoginData>({
    validator: (data) => data.cpf?.length < 11 || !data.password
  });

  async function handleSubmit() {
    setErrorMessage('');
    setIsLoading(true);
    const { data, isError } = await authApi.login(form);
    
    if (isError) {
      setIsLoading(false);
      setErrorMessage(data.message);
      return;
    }

    let finalArrayMenus: any = [];
    let menu = data.menu;
    Object.keys(menu).map((key, index) =>{
      if (menu[key].length){
        //icon
        /*let icon: any = null;
        switch (key) {
          case 'Dashboards':
            icon = MdDashboard;
            break;
          case 'Cadastros':
            icon = FaClipboardCheck;
            break;
          case 'Usuários':
            icon = FaUser;
            break;
        }*/
        let subMenus = [];
        subMenus = menu[key].map((dtk: any) => {
          return {
            'title': dtk.nome,
            'path': dtk.path,
          }
        });
        let dt = {
          'title': key,
          /*'icon': icon,*/
          subMenus
        }
        finalArrayMenus.push(dt);
      }
    });
    Storage.setApiToken(data.token);
    Storage.setUserData({ usuario: data.usuario });
    Storage.setUserMenu(finalArrayMenus);
    window.location.href = '/';
  }


  useEffect(() => {
    Storage.removeApiToken();
    Storage.removeUserData();

    document.title = 'Entrar';
  }, []);
  
  return (
    <div className="flex items-center justify-center h-screen p-5">

      <div className="lg:shadow lg:grid lg:grid-cols-2 w-full lg:w-max">
        <Form 
          className="bg-background shadow lg:shadow-none p-9 grid place-items-center mx-auto w-full max-w-[410px] rounded-tl-md rounded-bl-md"
          onSubmit={handleSubmit}
        >
          <img src={LogoSrc} className="max-w-[104px]" />

          <Title size="2xl" color="primary" className="mb-5 mt-8">Bem vindo - Portal Admin</Title>
          <p className="text-md text-secondary">Informe seus dados de acesso.</p>

          {!!errorMessage?.length && <p className="mt-5 text-red-600">{errorMessage}</p>}

          <Input 
            large
            hideLabel
            inputType="CPF" 
            className="my-5"
            value={form?.cpf}
            setValue={(v) => handleChange(v, 'cpf')}
          />

          <Input 
            large
            hideLabel
            inputType="password"
            value={form?.password}
            setValue={(v) => handleChange(v, 'password')}
          />

          <ColoredButton 
            className="mt-6 mb-4" 
            disabled={isFormInvalid} 
            loading={isLoading} 
            type="submit"
          >
            Entrar
          </ColoredButton>

          <a className="text-primary hover:text-primary-700 transition cursor-pointer" href={'/recuperar/senha'}>
            Esqueceu a senha?
          </a>
        </Form>

        <aside className="hidden lg:block">
          <div className="w-[410px] h-full relative">
            <img src={EstoqueSrc} className="object-cover w-full h-full rounded-tr-md rounded-br-md" />
            <div className="absolute inset-0 linear-gradient rounded-tr-md rounded-br-md opacity-80"></div>
            <div className="absolute inset-0 p-14 flex items-center">
              <h2 className="text-in-primary text-lg max-w-[300px]">
                Otimize seus processos de armazenagem e gerenciamento de estoques com a ajuda de nossa plataforma StockGO!
              </h2>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
