import React, { useEffect, useState } from 'react';

import { Cliente } from '../../../types';
import clientesApi from '../../../services/clientApi';

import useAppContext from '../../../hooks/useAppContext';

import RegisterPageLayout from '../../../components/layout/RegisterPageLayout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/Select';
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import PageContainer from "../../../components/container/PageContainer";
import PageCard from "../../../components/card/PageCard";
import {BiArrowBack} from 'react-icons/bi';
import IconButton from "../../../components/button/IconButton";
import Title from "../../../components/text/Title";
import Button from "../../../components/button/Button";
import Alert from "../../../helpers/Alert";
import useTable from "../../../hooks/useTable";
import departamentoApi from "../../../services/departamentoApi";
import Checkbox from "../../../components/checkbox/Checkbox";
import Table from "../../../components/table/Table";
export default function Client() {
    const params = useParams();
    const id = params.id;
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [departamentosCheck, setDepartamentosCheck] = useState<any>([]);
    const initialState = {
        cnpj: '',
        bloqueado: false,
        razao_social: '',
        nome_fantasia: '',
        responsavel: '',
        email: '',
        cpf: '',
        telefone_principal: '',
        telefone_celular: '',
        telefone_fixo: '',
        logradouro: '',
        numero: '',
        quadra: '',
        lote: '',
        complemento: '',
        cep: '',
        setor: '',
        cidade: '',
        UF: '',
        longitude: '',
        latitude: '',
        departamentos: [],
    };
    const appContext = useAppContext();

    const [initialData, setInitialData] = useState<Partial<Cliente>>(initialState);

    async function loadUserData() {
        if (!id) return;
        Alert.await('Carregando dados...');
        const {data, isError} = await clientesApi.one(id);
        setInitialData(data);
        if(data.departamentos){
            let dtDepartamento = [];
            dtDepartamento = data.departamentos.map((res: any) => {
                return res.id.toString();
            });
            setDepartamentosCheck(dtDepartamento);
        }

        /*let dtTipoLeituras = [];
        dtTipoLeituras = data.tipos_leitura.map((res) => {
            return res.id.toString();
        });
        setPesquisasCheck(dtTipoLeituras);
        if (isError) return alert(data.message || 'Falha ao carregar dados.');
        setState(data);*/
        setLoad(false);
        Alert.close();
    }

    useEffect(() => {
        if(id){
            setLoad(true);
            loadUserData();
        }
    }, [id]);

    const handleSubmit = () => {
        setLoad(true);
        if (id){
            update();
        }else{
            save();
        }
    };

    async function handleDelete(){
        if (id) {
            const {data, isError} = await clientesApi.destroy(id.toString());
            setLoad(false);
            if (isError) return Alert.error(data);
            Alert.success('Cliente deletado com sucesso.');
            navigate('/cadastros/clientes');
        }
    }

    async function save(){
        const state = initialData;
        state.departamentos = departamentosCheck;
        const { data, isError } = await clientesApi.create(state);
        setLoad(false);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }

            return Alert.error(data.message? data.message : data);
        }
        Alert.success('Cliente salvo com sucesso');
        navigate('/cadastros/clientes');
    }

    async function update() {
        if (id) {
            const state = initialData;
            state.departamentos = departamentosCheck;
            const { data, isError } = await clientesApi.update(id.toString(), state);
            setLoad(false);
            if (isError){
                if (typeof data === 'object') {
                    Object.keys(data).forEach(function(key, index) {
                        Alert.error(data[key][0]);
                    });
                    return;
                }

                return Alert.error(data.message? data.message : data);
            }
            Alert.success('Cliente editado com sucesso');
        }
    }

    const handleChange = (value: any, input: string) => {
        setInitialData({...initialData, [input]: value});
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setInitialData({ ...initialData, cliente_id: appContext.cliente?.id});
    }, [appContext.cliente?.id]);

    const { tableProps: departamentoTableProps, loadData } = useTable<any>({
        denyInitialLoad: true,
        dataLoader: async (params) => {
            const { data, isError } = await departamentoApi.all(params);
            return isError ? false : data;
        }
    });

    useEffect(() => {
        loadData();
    }, []);

    function onCkeckDepartamentos(l: any){
        if (departamentosCheck.includes(l.value.toString())){
            setDepartamentosCheck((departamentosCheck: any) =>
                departamentosCheck.filter((id: number) => id !== l.value)
            );
        }else{
            setDepartamentosCheck([...departamentosCheck, l.value]);
        }
    }

    return load ?
        <span className="flex items-center justify-center h-7">
          <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span> :
        (
        <PageContainer menuTitle="Cadastro de Clientes">
            <PageCard
                header={
                    <div className="flex items-center justify-between">
                        <Title color="primary">Cadastro de Clientes</Title>
                        <IconButton title="Voltar" icon={BiArrowBack} onClick={() => navigate('/cadastros/clientes')} />
                    </div>
                }
                footer={
                    <div className="flex items-center gap-5">
                        <Button loading={load} onClick={handleSubmit}>
                            Salvar
                        </Button>
                        {initialData.id && <Button loading={load} onClick={handleDelete}>Excluir</Button>}
                        {initialData.id && <Button loading={load} onClick={() => {window.location.href = '/cadastros/cadastro-clientes';}}>Novo</Button>}
                    </div>
                }
            >
                <>
                    <div className="border-b grid grid-cols-12 gap-5 pb-7">
                        <Input
                            label="CNPJ *"
                            inputType="CNPJ"
                            className="col-span-9"
                            value={initialData.cnpj}
                            setValue={v => handleChange(v, 'cnpj')}
                        />

                        <Select
                            label="Status do Cliente"
                            className="col-span-3"
                            value={initialData.bloqueado}
                            setValue={v => handleChange(v, 'bloqueado')}
                            items={[
                                { label: 'Ativo', key: false },
                                { label: 'Inativo', key: true },
                            ]}
                        />

                        <Input
                            label="Razão Social *"
                            className="col-span-6"
                            value={initialData.razao_social}
                            setValue={v => handleChange(v, 'razao_social')}
                        />

                        <Input
                            label="Nome Fantasia *"
                            className="col-span-6"
                            value={initialData.nome_fantasia}
                            setValue={v => handleChange(v, 'nome_fantasia')}
                        />
                    </div>

                    <div className="border-b grid grid-cols-11 gap-5 py-7">
                        <Input
                            label="Responsável *"
                            className="col-span-5"
                            value={initialData.responsavel}
                            setValue={v => handleChange(v, 'responsavel')}
                        />

                        <Input
                            label="E-mail *"
                            className="col-span-5"
                            value={initialData.email}
                            setValue={v => handleChange(v, 'email')}
                        />
                        <Input
                            label="CPF *"
                            inputType="CPF"
                            className="col-span-2"
                            value={initialData.cpf}
                            setValue={(v) => handleChange(v, 'cpf')}
                        />

                        <Input
                            label="Telefone Principal *"
                            mask="cellphone"
                            className="col-span-2"
                            value={initialData.telefone_principal}
                            setValue={v => handleChange(v, 'telefone_principal')}
                        />

                        <Input
                            label="Telefone Celular"
                            mask="cellphone"
                            className="col-span-2"
                            value={initialData.telefone_celular}
                            setValue={v => handleChange(v, 'telefone_celular')}
                        />

                        <Input
                            label="Telefone Fixo"
                            mask="phone"
                            className="col-span-2"
                            value={initialData.telefone_fixo}
                            setValue={v => handleChange(v, 'telefone_fixo')}
                        />


                    </div>

                    <div className="grid grid-cols-12 gap-5 py-7">
                        <Input
                            label="Logradouro *"
                            className="col-span-9"
                            value={initialData.logradouro}
                            setValue={v => handleChange(v, 'logradouro')}
                        />

                        <Input
                            label="Número *"
                            type="number"
                            min={0}
                            className="col-span-1"
                            value={initialData.numero}
                            setValue={v => handleChange(v, 'numero')}
                        />

                        <Input
                            label="Quadra"
                            className="col-span-1"
                            value={initialData.quadra}
                            setValue={v => handleChange(v, 'quadra')}
                        />

                        <Input
                            label="Lote"
                            className="col-span-1"
                            value={initialData.lote}
                            setValue={v => handleChange(v, 'lote')}
                        />

                        <Input
                            label="Complemento"
                            className="col-span-3"
                            value={initialData.complemento}
                            setValue={v => handleChange(v, 'complemento')}
                        />

                        <Input
                            label="CEP *"
                            inputType="CEP"
                            className="col-span-2"
                            value={initialData.cep}
                            setValue={v => handleChange(v, 'cep')}
                        />

                        <Input
                            label="Setor *"
                            className="col-span-3"
                            value={initialData.setor}
                            setValue={v => handleChange(v, 'setor')}
                        />

                        <Input
                            label="Cidade *"
                            className="col-span-3"
                            value={initialData.cidade}
                            setValue={v => handleChange(v, 'cidade')}
                        />

                        <Input
                            label="UF *"
                            inputType="UF"
                            className="col-span-1"
                            value={initialData.UF}
                            setValue={v => handleChange(v, 'UF')}
                        />

                        <Input
                            label="Longitude"
                            className="col-span-2"
                            value={initialData.longitude}
                            setValue={v => handleChange(v, 'longitude')}
                        />

                        <Input
                            label="Latitude"
                            className="col-span-2"
                            value={initialData.latitude}
                            setValue={v => handleChange(v, 'latitude')}
                        />
                    </div>

                    <div className={'mt-[2rem] p-5 border-t'}></div>

                    <div className="grid grid-cols-2 gap-2">
                        <div className="flex flex-col gap-2">
                            <Table<any> {...departamentoTableProps} columns={[
                                { title: '', width: 20, render: (l) => (
                                        <>
                                            <div>
                                                <Checkbox
                                                    name={'departamento-' + l.id}
                                                    onValue={l.id}
                                                    value={departamentosCheck.includes(l.id.toString())}
                                                    setValueTarget={onCkeckDepartamentos}
                                                />
                                            </div>
                                        </>
                                    )},
                                { title: 'Departamentos Vinculados', field: 'nome' },
                                { title: 'Status', field: 'status', columnType: 'status', width: 100 },
                            ]}/>
                        </div>
                    </div>
                </>
            </PageCard>
        </PageContainer>
        /*<RegisterPageLayout<Cliente>
            resourceName="Cliente"
            menuTitle="Cadastro de Clientes"
            finishPath="/cadastros/clientes"
            newPath="/cadastros/cadastro-clientes"
            returnPath="/cadastros/clientes"
            loader={clientesApi.one}
            destroyHandler={clientesApi.destroy}
            createHandler={clientesApi.create}
            updateHandler={clientesApi.update}
            initialData={initialData}
            formContent={(form, handleChange) => <>
                <div className="border-b grid grid-cols-12 gap-5 pb-7">
                    <Input
                        label="CNPJ *"
                        inputType="CNPJ"
                        className="col-span-9"
                        value={form.cnpj}
                        setValue={v => handleChange(v, 'cnpj')}
                    />

                    <Select
                        label="Status do Cliente"
                        className="col-span-3"
                        value={form.bloqueado}
                        setValue={v => handleChange(v, 'bloqueado')}
                        items={[
                            { label: 'Ativo', key: true },
                            { label: 'Inativo', key: false },
                        ]}
                    />

                    <Input
                        label="Razão Social *"
                        className="col-span-6"
                        value={form.razao_social}
                        setValue={v => handleChange(v, 'razao_social')}
                    />

                    <Input
                        label="Nome Fantasia *"
                        className="col-span-6"
                        value={form.nome_fantasia}
                        setValue={v => handleChange(v, 'nome_fantasia')}
                    />
                </div>

                <div className="border-b grid grid-cols-11 gap-5 py-7">
                    <Input
                        label="Responsável *"
                        className="col-span-5"
                        value={form.responsavel}
                        setValue={v => handleChange(v, 'responsavel')}
                    />

                    <Input
                        label="E-mail *"
                        className="col-span-5"
                        value={form.email}
                        setValue={v => handleChange(v, 'email')}
                    />
                    <Input
                        label="CPF *"
                        inputType="CPF"
                        className="col-span-2"
                        value={form.cpf}
                        setValue={(v) => handleChange(v, 'cpf')}
                    />

                    <Input
                        label="Telefone Principal *"
                        mask="cellphone"
                        className="col-span-2"
                        value={form.telefone_principal}
                        setValue={v => handleChange(v, 'telefone_principal')}
                    />

                    <Input
                        label="Telefone Celular"
                        mask="cellphone"
                        className="col-span-2"
                        value={form.telefone_celular}
                        setValue={v => handleChange(v, 'telefone_celular')}
                    />

                    <Input
                        label="Telefone Fixo"
                        mask="phone"
                        className="col-span-2"
                        value={form.telefone_fixo}
                        setValue={v => handleChange(v, 'telefone_fixo')}
                    />


                </div>

                <div className="grid grid-cols-12 gap-5 py-7">
                    <Input
                        label="Logradouro *"
                        className="col-span-9"
                        value={form.logradouro}
                        setValue={v => handleChange(v, 'logradouro')}
                    />

                    <Input
                        label="Número *"
                        type="number"
                        min={0}
                        className="col-span-1"
                        value={form.numero}
                        setValue={v => handleChange(v, 'numero')}
                    />

                    <Input
                        label="Quadra"
                        className="col-span-1"
                        value={form.quadra}
                        setValue={v => handleChange(v, 'quadra')}
                    />

                    <Input
                        label="Lote"
                        className="col-span-1"
                        value={form.lote}
                        setValue={v => handleChange(v, 'lote')}
                    />

                    <Input
                        label="Complemento"
                        className="col-span-3"
                        value={form.complemento}
                        setValue={v => handleChange(v, 'complemento')}
                    />

                    <Input
                        label="CEP *"
                        inputType="CEP"
                        className="col-span-2"
                        value={form.cep}
                        setValue={v => handleChange(v, 'cep')}
                    />

                    <Input
                        label="Setor *"
                        className="col-span-3"
                        value={form.setor}
                        setValue={v => handleChange(v, 'setor')}
                    />

                    <Input
                        label="Cidade *"
                        className="col-span-3"
                        value={form.cidade}
                        setValue={v => handleChange(v, 'cidade')}
                    />

                    <Input
                        label="UF *"
                        inputType="UF"
                        className="col-span-1"
                        value={form.UF}
                        setValue={v => handleChange(v, 'UF')}
                    />

                    <Input
                        label="longitude"
                        className="col-span-2"
                        value={form.longitude}
                        setValue={v => handleChange(v, 'longitude')}
                    />

                    <Input
                        label="latitude"
                        className="col-span-2"
                        value={form.latitude}
                        setValue={v => handleChange(v, 'latitude')}
                    />
                </div>
            </>}
        />*/
    );
}
